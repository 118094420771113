<template>
  <section>
    <div class="header pb-5">
      <app-navigation />

      <br />

      <div class="header-title">
        <div class="row">
          <div class="col-12 text-center">
            <h1>Skills</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div class="header-breadcrumb">
              <router-link to="/"><i class="fa fa-fw fa-home"></i></router-link> / Skills
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main pt-9">
      <div class="container">
        <p>As a 20 year industry veteran, I have amassed experience in many different areas providing me with the ability to bridge gaps between server administrators, developers, database administrators and more. Below are most of the skills that I have acquired over my career thus far.</p>

        <div class="row pb-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>DevOps Tools</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            Ansible, Jenkins, YAML, JSON, Github
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Programming Languages</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            PHP, JavaScript, TypeScript, BASH, CSS, XHTML, XML, JSON, SQL, YAML, JSON
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Programming Frameworks</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            REST, CakePHP, jQuery, Twitter Bootstrap, Node.JS, VueJS, Angular, Ionic, Cordova, ExpressJS
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Application Programming Interfaces / Integrations</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            Stripe, Facebook, Twitter, PayPal, AWS SDK, Google, Txtwire, Authorize.Net, AXIS VAPIX, FEMA IPAWS
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Databases / Data Stores</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            AWS Aurora, MySQL/MariaDB, PostgreSQL, MongoDB, Redis, ElasticSearch, AWS DynamoDB
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Servers, Networking and Data Center</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            Cloudflare, AWS Cloudfront, DataDog, Elastic (Kibana), Apache (httpd), Nginx, MySQL / MySQL Clustering, MongoDB, Redis, Postfix, Dovecot, cPanel, Plesk, Load Balancers, Switches, Routers, Firewalls, Structured Cabling, NFS/iSCSI/SAN, VPN, VOIP, SNMP, SMTP and more
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Software (Desktop, Web and Server)</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            Microsoft Office Suite (Word, Excel, PowerPoint, Visio, Outlook), Adobe Photoshop, PHPStorm, MySQL Workbench, Xcode, Splunk, OpenCart, Magento, RoundCube, AXIS Video Hosting Service (AVHS), SolusVM, WHMCS, Arduino IDE
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Operating Systems / Virtualization</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            Linux (RedHat/CentOS/Fedora/Ubuntu), VMware ESX/ESXi, OpenVZ, Citrix Xen, Microsoft Windows XP/7/8, Microsoft Windows Server 2000/2008/2012, Apple macOS, Apple iOS, Google Android
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Amazon Web Services</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            VPC, RDS, SES, SNS, S3, ELB, Route53, SQS, EC2, CodeCommit, ElastiCache Redis, WorkMail, SDK/API, and more
          </div>
        </div>
        <div class="row pb-2 pt-2">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
            <strong>Other</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            Bluetooth Classic, Bluetooth LE, AXIS Surveillance Cameras, Arduino Prototyping, Ubiquiti Networks
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </section>
</template>

<script>
import appNavigation from '../components/Navigation'
import appFooter from '../components/Footer.vue'

export default {
  components: {
    appNavigation,
    appFooter
  },
  head: {
    title: "My Skills | DevOps Engineer, AWS Engineer, Software Developer, VueJS Developer, NodeJS Developer, PHP Developer, and more | Adam L Miller",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'I am a seasoned veteran in AWS DevOps as well as Software Development using VueJS, NodeJS, PHP and more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'devops engineer, aws engineer, software developer, vuejs developer, nodejs developer, php developer'
      }
    ]
  }
}
</script>